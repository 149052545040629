import _ from 'lodash';
import React, { useState, useEffect, useRef, useCallback, useContext, useReducer } from 'react';
import { useParams } from 'react-router-dom';
import { ChatHistoryProvider } from '../components/Chatroom/ChatHistoryContext';

import theme from '#root/src/config/theme.js'
// import Chatroom from '../components/chatroom'
import Chatroom from '../components/Chatroom/Chatroom'
import Compare from '../components/compare/Compare'
import Upload from '../components/upload'

// styles
import '../styles/menu.scss';

const Menu = () => {

  const { company = 'aim' } = useParams();
  document.documentElement.style.setProperty('--background-color', (theme[company]?.backgroundColor || theme['aim'].backgroundColor))
  document.documentElement.style.setProperty('--company-identity-color', (theme[company]?.companyIdentityColor || theme['aim'].companyIdentityColor))
  document.documentElement.style.setProperty('--svg-filter', (theme[company]?.filter || theme['aim'].filter))

  const [section, setSection] = useState("chatroom");

  const [productCode1, setProductCode1] = useState(null);
  const [productCode2, setProductCode2] = useState(null);
  const [selectedProductCodes, setSelectedProductCodes] = useState([null]);

  function setComparedProducts(productCodes) {
    setSelectedProductCodes(productCodes)
    setSection("compare")
  }

  function backToChat() {
    setSection("chatroom")
  }

  return (
    <div className="container">
      {/* <div className='title-bar'>
        <div className='section-tabs'>
          <button className={section === "chatroom" || section === "compare" ? "selected" : ""} key="chatroom" onClick={() => setSection("chatroom")}>Chatroom</button>
          <button className={section === "upload" ? "selected" : ""} key="upload" onClick={() => setSection("upload")}>Upload product documents</button>
          <button className={section === "compare" ? "selected" : ""} key="compare" onClick={() => setSection("compare")}>Compare product features</button>
        </div>
      </div> */}

      <div
        className={
          'section-container' + (section !== 'chatroom' ? ' hidden' : '')
        }
      >
        {/* <Chatroom setComparedProducts={setComparedProducts} /> */}
        <ChatHistoryProvider>
          <Chatroom setComparedProducts={setComparedProducts} />
        </ChatHistoryProvider>
      </div>
      <div
        className={
          'section-container' + (section !== 'upload' ? ' hidden' : '')
        }
      >
        <Upload />
      </div>
      <div
        className={
          'section-container' + (section !== 'compare' ? ' hidden' : '')
        }
      >
        <Compare
          selectedProductCodes={selectedProductCodes}
          setSelectedProductCodes={setSelectedProductCodes}
          backToChat={backToChat}
        />
      </div>
    </div>
  );

};

export default Menu;
