import { createContext, useContext, useReducer } from 'react';

import { companyNameMapper } from '#root/src/config/config.js';

const ChatHistoryContext = createContext(null);

const ChatHistoryDispatchContext = createContext(null);

export function ChatHistoryProvider({ children }) {
  const [chatHistory, dispatchChatHistory] = useReducer(chatHistoryReducer, []);

  return (
    <ChatHistoryContext.Provider value={chatHistory}>
      <ChatHistoryDispatchContext.Provider value={dispatchChatHistory}>
        {children}
      </ChatHistoryDispatchContext.Provider>
    </ChatHistoryContext.Provider>
  );
}

export function useChatHistory() {
  return useContext(ChatHistoryContext);
}

export function useChatHistoryDispatch() {
  return useContext(ChatHistoryDispatchContext);
}

function chatHistoryReducer(state, action) {
  const company = action.company;
  
  switch (action.type) {
    case 'fetch': {
      let newState = action.payload.conversations ?? [];
      const welcomeMessage = {
        role: 'system',
        content: {
          text: `Welcome! Feel free to ask anything about insurance products.`,
          resources: [
            {
              type: 'productCategories',
              data: action.payload.productMapping,
            },
          ],
        },
      };
      newState.push(welcomeMessage);
      return newState;
    }
    case 'append': {
      const newState = state.slice(); // shallow copy
      newState.push(action.payload); // append message
      return newState;
    }
    case 'update': {
      let newState = state.slice(); // shallow copy
      const { filter, payload } = action;
      let indexes = [];
      try {
        indexes = newState.reduce(
          (acc, chat, idx) => (filter(chat) ? [...acc, idx] : acc),
          []
        );
      } catch (error) {
        console.error(error);
        return state;
      }
      if (indexes.length === 0) {
        return state;
      }
      newState = newState.map((chat, idx) =>
        !indexes.includes(idx) ? chat : payload
      );
      return newState;
    }
    case 'stream': {
      const newState = state.slice(); // shallow copy
      const { streamId } = action.payload;
      const idx = newState.findIndex(
        (chat) => chat.streamId === streamId
      );
      if (idx === -1) {
        return state;
      }
      newState[idx].messageId = action.payload.messageId;
      newState[idx].content.text += action.payload.content.text;
      newState[idx].status = 'streaming';
      return newState;
    }
    case 'clear': {
      return [];
    }
    case 'cross-check': {
      // not implemented yet. maybe use lodash differenceBy() if necessary
      return state;
    }
    default: {
      return state;
    }
  }
}
