const companyNameMapper = {
  'aim': 'AI Momentum',
  'aia': 'AIA',
  'nwlife': 'New World Life',
  'gta': 'SwimRun Association',
  'swire': 'Swire Properties',
  'sunlife': 'Sun Life',
  'shk': 'Sun Hung Kai Properties',
  'clp': 'China Light and Power Company',
  'eduhk': 'The Education University of Hong Kong'
}

const agentMode = {
  NormalAdvisor: 'NormalAdvisor',
  SupportService: 'SupportService',
  SalesAdvisor: 'SalesAdvisor',
}

export {
  companyNameMapper,
  agentMode
}