import React from 'react';
import Select, { components } from 'react-select';
import { Tags } from '../Tags';

import filterLogo from '../../assets/filter.svg';
import mopLogo from '../../assets/mop.svg';

// import './AiNavigator.scss';

const defaultProductCategory =
  process.env.REACT_APP_NODE_ENV === 'development'
    ? { value: null, label: 'All Categories' }
    : {};
const defaultProductName = { value: null, label: 'All Products' };

const AiNavigator = ({
  productCategory,
  productMapping,
  productContext,
  setProductCategory,
  setProductName,
  productName,
  showCleanHistoryBtn,
  handleCleanContext,
}) => {
  const productStyles = {
    control: (baseStyles, state) => ({
      ...baseStyles,
      'border-radius': '10px',
      border: `2px solid var(--company-identity-color)`,
      'padding-top': '11px',
    }),
    option: (baseStyles, { data, isDisabled, isFocused, isSelected }) => ({
      ...baseStyles,
      // color: isDisabled ? "grey" : data.value ? "black" : "#009188"
    }),
  };

  const Control = ({ children, ...props }) => (
    <components.Control {...props}>
      <div
        style={{
          fontSize: '11px',
          fontWeight: '600',
          color: 'var(--company-identity-color)',
          position: 'absolute',
          top: '-5px',
          padding: '8px',
        }}
      >
        Product Category:
      </div>
      {children}
    </components.Control>
  );

  const categoryOptions = productMapping.map(
    ({ productCategory, products }) => ({
      value: productCategory.name,
      label: productCategory.name,
      isDisabled: !productCategory.haveInfo,
    })
  );
  const productCategoryOptions = [
    defaultProductCategory,
    ...categoryOptions,
    { value: null, label: 'Life' },
  ].filter((option) => !(option && Object.keys(option).length === 0));

  const showProductSelect =
    productCategory?.value &&
    productMapping.find(
      (product) => product.productCategory.name === productCategory.label
    )?.products.length > 0;

  return (
    <div className="product-container">
      <div>
        <img className="filter-logo" src={filterLogo} />
      </div>
      <div className="select-container">
        <Select
          // ref={productCategorySelect}
          placeholder="Select..."
          value={productCategory}
          onChange={(e) => {
            setProductCategory(e);
            setProductName(null);
          }}
          menuPlacement="top"
          styles={productStyles}
          options={productCategoryOptions}
          components={{ Control }}
        />
      </div>
      {showProductSelect && (
        <div className="select-container">
          <Select
            placeholder="Product Name"
            value={productName}
            onChange={setProductName}
            menuPlacement="top"
            styles={productStyles}
            options={[
              defaultProductName,
              ...productMapping
                .find(
                  (product) =>
                    product.productCategory.name === productCategory.label
                )
                .products.map(({ name, haveInfo }) => ({
                  value: name,
                  label: name,
                  isDisabled: !haveInfo,
                })),
            ]}
          />
        </div>
      )}
      <div className="product-code-container">
        <Tags
          values={productContext?.map((context) => context.productCode) ?? []}
        ></Tags>
      </div>
      {showCleanHistoryBtn && (
        <div className="clean-context-btn-container">
          <button
            className="clean-context-btn"
            onClick={() => handleCleanContext()}
          >
            <img alt="clean-context" src={mopLogo}></img>
          </button>
        </div>
      )}
    </div>
  );
};

export default AiNavigator;
