import React, { useEffect, useRef } from 'react';

import sendLogo from '../../assets/send.svg';
import { useChatHistory } from './ChatHistoryContext';

// import './ChatHistory.scss';

import Message from '../message';

const ChatHistory = ({
  company,
  handleAsk,
  setProductCategory,
  productCategorySelect,
  setComparedProducts,
  handleFactCheck,
  sessionId,
  chatBottom,
  scrollToBottom,
}) => {
  const chatHistoryContainer = useRef(null);
  const chatMessagesRef = useRef([]);
  // chat history
  const chatHistory = useChatHistory();

  useEffect(() => {
    const chatHistoryEle = chatHistoryContainer?.current;
    const lastMessageEle = chatMessagesRef?.current.slice(-1)[0];
    const lastMessageEleHeight = lastMessageEle?.offsetHeight || 0;
    const sh = chatHistoryEle?.scrollHeight || 0;
    const st = chatHistoryEle?.scrollTop || 0;
    const ch = chatHistoryEle?.clientHeight || 0;
    // Is near the bottom of the chat
    const nearBottom = sh - st - ch - lastMessageEleHeight <= 80;

    console.log('nearBottom', nearBottom);

    // If the new message is from the assistant
    if (nearBottom && chatHistory.slice(-1)[0]?.status === 'streaming') {
      scrollToBottom('instant');
    } else if (nearBottom) {
      scrollToBottom();
    }
  }, [chatHistory]);

  return (
    <div className={`chat-history-container`} ref={chatHistoryContainer}>
      {!chatHistory?.length ? (
        <div className={`center-items`}>
          <div>No conversation yet</div>
        </div>
      ) : (
        chatHistory.map((history, idx, array) => (
          <Message
            useBubble={true}
            key={idx}
            index={idx}
            chatMessagesRef={chatMessagesRef}
            role={history.role}
            contentText={history.content?.text}
            theme={company}
            contentResources={history.content?.resources}
            metadata={history.metadata}
            messageId={history.messageId}
            sessionId={history.sessionId}
            feedbackAction={history.feedbackAction}
            feedbackContent={history.feedbackContent}
            isError={history.isError}
            verificationResult={history.verificationResult}
            buttonsClickable={array.length - idx === 1} // only clickable if it's the last message
            handleClick={(interruptQuery) => handleAsk(interruptQuery)}
            handleProductCategory={(selectedProductCategory) => {
              const category = {
                value: selectedProductCategory,
                label: selectedProductCategory,
                isDisabled: false,
              };
              setProductCategory(category);
              productCategorySelect.current.setValue(category);
            }}
            handleProductComparison={(products) => {
              setComparedProducts(products);
            }}
            handleFactCheck={handleFactCheck}
            showFactCheckBtn={history.sessionId == sessionId}
            status={history.status}
          />
        ))
      )}
      <div ref={chatBottom} id="bottom"></div>
    </div>
  );
};

export default ChatHistory;
