const companyTheme = {
  'aim': {
    name: "AIM",
    backgroundColor: '#f7f7f8',
    companyIdentityColor: '#02b98c',
    filter: "invert(44%) sepia(94%) saturate(1397%) hue-rotate(132deg) brightness(97%) contrast(98%)"
  },
  'aia': {
    name: "AIA",
    backgroundColor: '#f7f7f8',
    companyIdentityColor: '#d31145',
    filter: "invert(12%) sepia(99%) saturate(4123%) hue-rotate(335deg) brightness(98%) contrast(95%)"
  },
  'nwlife': {
    name: "New World Life",
    backgroundColor: '#efefef',
    companyIdentityColor: '#009188',
    filter: "invert(33%) sepia(96%) saturate(1305%) hue-rotate(151deg) brightness(90%) contrast(101%)"
  },
  'gta': {
    name: "SwimRun Association",
    backgroundColor: '#efefef',
    companyIdentityColor: '#877547',
    filter: "invert(45%) sepia(52%) saturate(326%) hue-rotate(5deg) brightness(91%) contrast(91%)"
  },
  'swire': {
    name: "Swire Properties",
    backgroundColor: '#efefef',
    companyIdentityColor: '#877547',
    filter: "invert(45%) sepia(52%) saturate(326%) hue-rotate(5deg) brightness(91%) contrast(91%)"
  },
  'sunlife': {
    name: "Sun Life",
    backgroundColor: '#efefef',
    companyIdentityColor: '#877547',
    filter: "invert(45%) sepia(52%) saturate(326%) hue-rotate(5deg) brightness(91%) contrast(91%)"
  },
  'shk': {
    name: "Sun Hung Kai Properties",
    backgroundColor: '#efefef',
    companyIdentityColor: '#8f7e62',
    filter: 'invert(52%) sepia(34%) saturate(315%) hue-rotate(358deg) brightness(90%) contrast(84%)'
  },
  'clp': {
    name: "China Light and Power Company",
    backgroundColor: '#efefef',
    companyIdentityColor: '#0a59a4',
    filter: 'invert(22%) sepia(39%) saturate(4875%) hue-rotate(196deg) brightness(88%) contrast(92%)'
  },
  'eduhk': {
    name: "The Education University of Hong Kong",
    backgroundColor: '#efefef',
    companyIdentityColor: '#036436',
    filter: 'invert(22%) sepia(34%) saturate(4940%) hue-rotate(141deg) brightness(84%) contrast(98%)'
  }
}

export default companyTheme