import React, { useState, useContext, useCallback, useEffect, useRef } from 'react';

import { useNavigate, useParams } from 'react-router-dom';
import UserContext from '../contexts/user/user'
import nwBanner from '../assets/nw-banner.png'
import ftNwBanner from '../assets/ft-nw-banner.png'
import ppLogo from '../assets/pacific-place-logo.png'
import slLogo from '../assets/sun-life-logo.png'
import shkLogo from '../assets/sun-hung-kai-logo.png'
import aiaLogo from '../assets/aia-logo.png'
import aimLogo from '../assets/aim-full-logo.png'
import clpLogo from '../assets/clp-logo.png'
import eduhkLogo from '../assets/eduhk-logo.png'
import '../styles/login.scss'


const Login = () => {
  const { company } = useParams();

  // login panel
  const [loginUsername, setLoginUsername] = useState('')
  const [loginPassword, setLoginPassword] = useState('')
  const [loginDistrict, setLoginDistrict] = useState('')
  const [attemptedLogin, setAttemptedLogin] = useState(false)
  const [isAdminLogin, setIsAdminLogin] = useState(false)

  // change password panel
  const [changeUsername, setChangeUsername] = useState('')
  const [changeOldPassword, setChangeOldPassword] = useState('')
  const [changeNewPassword, setChangeNewPassword] = useState('')
  const [changeConfirmPassword, setChangeConfirmPassword] = useState('')
  const [changeVerified, setChangeVerified] = useState(false)
  const [changePasswordMessage, setChangePasswordMessage] = useState('')

  // forgot panel
  const [forgetEmail, setForgetEmail] = useState('')
  const [forgotPasswordMessage, setForgotPasswordMessage] = useState('')

  // which panel to show
  const [showForgotOptions, setShowForgotOptions] = useState(false)
  const [showChangeOptions, setShowChangeOptions] = useState(false)

  const { userId, agreedToTnC, hasLoginError, errorMessage, login, logout, verifyToken, agreeToTnC } = useContext(UserContext)
  const [seenDisclaimer, setSeenDisclaimer] = useState(false)
  const dialog = useRef(null)

  const navigate = useNavigate();

  const resetEntries = useCallback(() => {
    setLoginUsername('')
    setLoginPassword('')
    setLoginDistrict('')
    setChangeUsername('')
    setChangeOldPassword('')
    setChangeNewPassword('')
    setChangeConfirmPassword('')
    setChangeVerified(false)
    setChangePasswordMessage('')
    setForgetEmail('')
    setForgotPasswordMessage('')
  }, [setLoginUsername, setLoginPassword, setLoginDistrict,
    setChangeUsername, setChangeOldPassword, setChangeNewPassword, setChangeConfirmPassword,
    setChangeVerified, setChangePasswordMessage,
    setForgetEmail, setForgotPasswordMessage,])

  const checkPasswordComplexity = (newPassword = '') => {
    if (!newPassword) {
      return 'Password must not be empty.'
    }
    if (newPassword.length < 8) {
      return 'Passwords must be 8 characters long'
    }
    const missingParts = [];
    if (newPassword.replace(/[a-z]/, '') === newPassword) {
      missingParts.push('lower case characters')
    }
    if (newPassword.replace(/[A-Z]/, '') === newPassword) {
      missingParts.push('upper case characters')
    }
    if (newPassword.replace(/[0-9]/, '') === newPassword) {
      missingParts.push('numeric digits')
    }
    if (newPassword.replace(/[!@#$%^&*]/, '') === newPassword) {
      missingParts.push('symbols (!@#$%^&*)')
    }
    if (!missingParts.length) {
      return ''
    }
    const missing = missingParts.reduce((prev, curr, index) => {
      if (missingParts.length === 1) {
        return `${curr}`
      }
      else if (index === 0) {
        return `${curr}`
      }
      else if (missingParts.length === 2) {
        return `${prev} and ${curr}`
      }
      else if (index === missingParts.length - 1) {
        return `${prev}, and ${curr}`
      }
      else {
        return `${prev}, ${curr}`
      }
    }, '')
    return `Password must contain ${missing}.`
  }

  useEffect(() => {
    const accessToken = sessionStorage.getItem('access_token')
    const refreshToken = sessionStorage.getItem('refresh_token')
    if (!attemptedLogin && accessToken && refreshToken) {
      console.log('Trying to login using stored tokens')
      setAttemptedLogin(true)
      verifyToken(accessToken, refreshToken)
    }
  }, [verifyToken, attemptedLogin])

  useEffect(() => {
    const message = checkPasswordComplexity(changeNewPassword)
    if (changeUsername === '' || changeOldPassword === '' || changeNewPassword === '' || changeConfirmPassword === '') {
      setChangeVerified(false)
    }
    else if (message) {
      setChangeVerified(false)
      setChangePasswordMessage(message)
    }
    else if (changeNewPassword !== changeConfirmPassword) {
      setChangeVerified(false)
      setChangePasswordMessage('New passwords does not match.')
    }
    else {
      setChangeVerified(true)
      setChangePasswordMessage('')
    }
  }, [changeUsername, changeOldPassword, changeNewPassword, changeConfirmPassword])

  const handleLogin = useCallback(() => {
    setAttemptedLogin(true)
    login(
      loginUsername,
      loginPassword !== '' ? loginPassword : 'Passw0rd!',
      isAdminLogin,
      loginDistrict)
  }, [login, logout, attemptedLogin, loginUsername, loginPassword, loginDistrict, isAdminLogin])

  useEffect(() => {
    if (userId !== null) {
      if (!agreedToTnC && !seenDisclaimer) {
        dialog.current.showModal()
      }
      else {
        if (company){
          navigate(`/company/${company}`);
        } else {
          navigate('/');
        }
      }
    }
  }, [userId, navigate, agreedToTnC, seenDisclaimer])

  const handleEnter = async (event) => {
    event.preventDefault()
    if (event.key === "Enter" && (
      (isAdminLogin && loginUsername !== '' && loginPassword !== '') ||
      (!isAdminLogin && loginUsername !== ''))) {
      handleLogin()
    }
  }
  return (
    <div className='container'>
      <div className='center-box'>
        <div className='login-box'>
          <div className='login-box-header'>
            <img alt='Banner' className='banner' src={(() => {
              return aimLogo
              switch(company){
                case "nwlife":
                  return ftNwBanner
                case "swire":
                  return ppLogo
                case "sunlife":
                  return slLogo
                case "shk":
                  return shkLogo
                case "aia":
                  return aiaLogo
                case "clp":
                  return clpLogo
                case "eduhk":
                  return eduhkLogo
                default:
                  return aimLogo
              }
            })()} />
            <label>AI-Enhanced Guidance and Information Service (AEGIS)</label>
          </div>
          {!showChangeOptions && !showForgotOptions &&
            <div className='option-box'>
              {!isAdminLogin && <label className='option-box-header'>User Login</label>}
              {isAdminLogin && <label className='option-box-header'>Admin Login</label>}
              <div className='input-area'>
                <div className='input-row'>
                  <input type='email' placeholder='Username'
                    onKeyUp={(event) => handleEnter(event)}
                    onChange={e => setLoginUsername(e.target.value)}
                    value={loginUsername}
                  /></div>
                {!isAdminLogin &&
                  <div className='input-row'>
                    <input type='email'
                      placeholder='District'
                      onChange={e => setLoginDistrict(e.target.value)}
                      onKeyDown={(event) => event.stopPropagation()}
                      onKeyUp={(event) => handleEnter(event)}
                      value={loginDistrict} />
                  </div>
                }
                {isAdminLogin &&
                  <div className='input-row'>
                    <input type='password'
                      placeholder='Password'
                      onChange={e => setLoginPassword(e.target.value)}
                      onKeyDown={(event) => event.stopPropagation()}
                      onKeyUp={(event) => handleEnter(event)}
                      value={loginPassword} />
                  </div>
                }
              </div>
              {hasLoginError && errorMessage && <label className='alert-text'>{errorMessage}</label>}
              {isAdminLogin ?
                <div>
                  <div><button disabled={loginUsername === '' || loginPassword === ''} onClick={() => handleLogin()}>Sign in</button></div>
                  <div><button onClick={() => { setIsAdminLogin(false); setLoginPassword('') }}>User login</button></div>
                </div>
                :
                <div>
                  <div><button disabled={loginUsername === '' || loginDistrict === ''} onClick={() => handleLogin()}>Sign in</button></div>
                  <div><button onClick={() => { setIsAdminLogin(true); setLoginDistrict('') }}>Admin login</button></div>
                </div>
              }
              <hr />
              <div>
                {/* <button onClick={() => { setShowChangeOptions(true); resetEntries() }}>Change password</button>
                <button onClick={() => { setShowForgotOptions(true); resetEntries() }}>Forgot password</button> */}
                <div>By logging in, you agree to our <button id='disclaimer-button' onClick={() => {
                  dialog.current.showModal()
                }}> Terms & Conditions</button>.</div>
              </div>
            </div>
          }
          {
            showChangeOptions &&
            <div className='option-box'>
              <label className='option-box-header'>Change password</label>
              <div className='input-area'>
                <div className='input-row'><input type='email' placeholder='Username' onChange={e => setChangeUsername(e.target.value)} value={changeUsername} /></div>
                <div className='input-row'><input type='password' placeholder='Old password' onChange={e => setChangeOldPassword(e.target.value)} value={changeOldPassword} /></div>
                <div className='input-row'><input type='password' placeholder='New password' onChange={e => setChangeNewPassword(e.target.value)} value={changeNewPassword} /></div>
                <div className='input-row'><input type='password' placeholder='Confirm new password' onChange={e => setChangeConfirmPassword(e.target.value)} value={changeConfirmPassword} /></div>
              </div>
              {changePasswordMessage && <label className='alert-text'>{changePasswordMessage}</label>}
              <div><button disabled={!changeVerified} onClick={() => setShowChangeOptions(false)}>Submit</button></div>
              <hr />
              <div><button onClick={() => { setShowChangeOptions(false); resetEntries() }}>Back to login</button></div>

            </div>
          }
          {
            showForgotOptions &&
            <div className='option-box'>
              <label className='option-box-header'>Reset password</label>
              <div className='input-area'>
                <div className='input-row'><input type='email' placeholder='Username' onChange={e => setForgetEmail(e.target.value)} value={forgetEmail} /></div>
              </div>
              <div><button disabled={forgetEmail === ''} onClick={() => setForgotPasswordMessage('Your request has been sent.')}>Submit</button></div>
              {forgotPasswordMessage && <label>{forgotPasswordMessage}</label>}
              <hr />
              <div><button onClick={() => { setShowForgotOptions(false); resetEntries() }}>Back to login</button></div>
            </div>
          }

          <dialog className='disclaimer' ref={dialog} onClose={()=> logout()}>
            <label className='option-box-header'>Terms and Conditions for using AEGIS</label>
            <div className='tnc'>
              <ol>
                <li>
                  <u>Acceptance of the Terms of Use</u>
                  <p>
                    <ol>
                      <li>
                        AEGIS ("<strong>System</strong>")  is an AI-Virtual Assistant provided by AI Momentum Limited ("<strong>AI Momentum</strong>" or "<strong>we</strong>" or "<strong>us</strong>") through AI Momentum’s website <a href="http://www.AI Momentum.com">www.AI Momentum.com</a> ("<strong>Website</strong>") to, at the present stage, a limited number of authorized users who are employees and appointed insurance agents of AI Momentum Insurance Company Limited (a company incorporated in Bermuda with limited liability) ("<strong>AI Momentum</strong>") who are selected to support the trial testing of the System.
                      </li>
                      <li>
                        These terms and conditions ( "Terms and Conditions") govern your access to and use of the System.  By clicking the "I AGREE" button, you confirm that:
                        <ol>
                          <li>you have fully read, understood and agree to be bound by these Terms and Conditions on the use of the System;</li>
                          <li>you are the person to whom AI Momentum has issued the login ID and password to access the System;</li>
                          <li>you are not purporting to enter into a user account that belongs to another person;</li>
                          <li>you understand and agree that relevant employees of AI Momentum and/or AI Momentum have the right to access your user account for the purpose of analyzing and evaluating the System’s accuracy, quality, effectiveness and for service enhancement.If you do not agree to these Terms and Conditions, you shall not access or use the System.</li>
                        </ol>
                      </li>
                    </ol>
                  </p>
                </li>
                <li>
                  <u>Use of the System</u>
                  <p>
                    <ol>
                      <li>
                        You agree and understand that:
                        <ol>
                          <li>
                            the System is a virtual assistant which seeks to answer general enquiries from agents or internal staff using an automated service programme. All information to be provided by the System are intended for reference only and do not constitute financial, investment, taxation or any other type of advice;
                          </li>
                          <li>
                            the conversation, content and feedback in the System will be analyzed and used by relevant employees of AI Momentum and AI Momentum for the purpose of analyzing the System’s accuracy, quality, effectiveness and for service enhancement;
                          </li>
                          <li>you are required to provide the user ID and password for initial login before the conversation commences; </li>
                          <li>you shall not enter any personal data while using the System. Personal data includes, but is not limited to, your name, address, phone number, email address, date of birth, identity number, or any other sensitive information that could be used to identify you, your customers or prospects;While we strive to ensure the security of the System and protect your privacy, we cannot guarantee that unauthorized third parties will not be able to access the personal data you input into the system. Therefore, we strongly advise against entering any personal data while using the System.By using the System, you agree to release AI Momentum and us from any and all liability arising from the unauthorized access to or use of any personal data entered into the System.  AI Momentum and AI Momentum are not responsible for any loss, damage, or injury resulting from the use of the System, including any unauthorized access to or use of personal data.</li>
                          <li>The content and details of any conversation between you and the System will be retained by AI Momentum for 2 years.</li>
                        </ol>
                      </li>
                      <li>We reserve the right to withdraw or amend the System, and any material we provide on the System, the Website, these Terms and Conditions at our sole discretion without notice.</li>
                      <li>
                        In connection with your use of the System:
                        <ol>
                          <li>you shall not violate any applicable local, national or international law or regulations;</li>
                          <li>you shall not let any unauthorized persons access the System or use the System without AI Momentum’s prior written consent; and</li>
                          <li>
                            you shall not, except with the prior written consent of AI Momentum, at any time, use, publish, divulge or communicate to any person (including any of your customers or prospects) the content of the System (except those which is publicly available or lawfully received or obtained from other source free from any restrictions or any breach of this clause).
                          </li>
                        </ol>
                      </li>
                    </ol>
                  </p>
                </li>
                <li>
                  <u>Intellectual Property Right</u>
                  <p>
                    <ol>
                      <li>
                        The content of the System is protected by copyright, trademarks, service marks, international treaties and/or other proprietary rights and laws.  The copyright, trademarks and any other intellectual property rights subsisting in or used in connection with the content in the System including, without limitation, the manner in which it is presented or appears are the property of AI Momentum and/or AI Momentum.  You agree to abide by all applicable copyright and other laws, as well as any additional copyright notices or restrictions contained in the content.
                      </li>
                      <li>
                        No information provided to you through the System or collected by you may be reproduced, transmitted, distributed or stored in a retrieval system, in any format or by any means, electronic, mechanical, photocopying, recording, or otherwise, without the prior written permission of AI Momentum.
                      </li>
                      <li>
                        The information as processed by and/or provided to you is the property of AI Momentum and/or AI Momentum and must not be used in any manner other than strictly in accordance with your agency or employment contract or any other applicable agreement with AI Momentum.
                      </li>
                      <li>
                        All materials, whether tangible or intangible, provided to you through the System shall remain at all times the property of AI Momentum and/or AI Momentum and shall be returned and/or destroyed forthwith upon demand or at the termination of your agency or employment contract with AI Momentum, or any other applicable agreement with AI Momentum.
                      </li>
                    </ol>
                  </p>
                </li>
                <li>
                  <u>Warranties and Disclaimers</u>
                  <p>The information and materials contained in the System are provided on an "as is" and "as-available" basis without representation and/or warranty of any kind, either express or implied. In particular, no warranty or responsibility is assumed by AI Momentum or AI Momentum and our related or holding companies regarding security, accuracy, completeness, adequacy in connection with the information and materials provided.</p>
                </li>
                <li>
                  <u>Limitation of Liability</u>
                  <p>
                    <ol>
                      <li>
                        To the fullest extent permitted by applicable laws, in the absence of gross negligence, wilful default or fraud, AI Momentum and AI Momentum shall not be liable to you in contract, tort, breach of statutory duty or for any losses of any nature or type (whether general, special, indirect, direct, nominal, punitive, incidental or consequential) which you may incur or suffer arising from or in connection with the use of the System.
                      </li>
                      <li>
                        You agree that AI Momentum has no responsibility or liability for the deletion or corruption of or failure to store any messages or other content maintained or transmitted by the System.  AI Momentum makes no warranty that the System will be uninterrupted, timely, secure or error-free.  AI Momentum cannot guarantee or ensure the accuracy, completeness or authenticity of any content, functionality, or transmission of any content from the System to you.
                      </li>
                    </ol>
                  </p>
                </li>
                <li>
                  <u>Termination</u>
                  <p>
                    You can stop using the System at any time. We have the right to suspend or terminate your access to and use of the System at any time at our sole discretion and without notice. We also have the right to amend, delete or cease providing (in whole or in part) the System from time to time at our sole discretion.
                  </p>
                </li>
                <li>
                  <u>Severability</u>
                  <p>
                    If any term of these Terms and Conditions shall be held to be illegal or unenforceable, in whole or in part, under any enactment or rule of law, the term shall to that extent be deemed not to form part of these Terms and Conditions and the enforceability of the remainder of these Terms and Conditions shall not be affected.
                  </p>
                </li>
                <li>
                  <u>Third Party Rights</u>
                  <p>
                    <ol>
                      <li>
                        Subject to Clause 8.2 of these Terms and Conditions, a person who is not a party to these Terms and Conditions has no right under the Contracts (Rights of Third Parties) Ordinance (Cap. 623 of the Laws of Hong Kong) to enforce or to enjoy the benefit of any term of these Terms and Conditions.
                      </li>
                      <li>
                        These Terms and Conditions may be enforced by AI Momentum and its affiliated companies in accordance with the Contracts (Rights of Third Parties) Ordinance.
                      </li>
                      <li>
                        The consent of any of AI Momentum’s affiliated companies or its respective personnel is not required to rescind or vary these Terms and Conditions at any time.
                      </li>
                    </ol>
                  </p>
                </li>
                <li>
                  <u>Governing Law</u>
                  <p>
                    These Terms and Conditions are made under and shall be construed according to the laws of the Hong Kong Special Administrative Region and the parties hereto submit to the non-exclusive jurisdiction of the courts of Hong Kong.
                  </p>
                </li>
              </ol>
            </div>
            <hr />
            <div className="button-container">
              <button onClick={() => { setSeenDisclaimer(true); agreeToTnC(); dialog.current.close() }}>I AGREE</button>
              <button className="cancel" onClick={() => { dialog.current.close() }}>Cancel</button>
            </div>
          </dialog>
        </div >
      </div>
      <footer>
        Powered by AI Momentum
      </footer>
    </div>
  );
};

export default Login;
