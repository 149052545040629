import React, { useState } from 'react';
import downloadIcon from '../../assets/download-white.svg';
import loadingIcon from '../../assets/loading-white.svg';

import userLogo from '../../assets/user-solid.svg';
import logoutLogo from '../../assets/logout.png';
import downloadLogo from '../../assets/download.png';

import './TitleBar.scss';

import { agentMode } from '../../config/config'

const TitleBar = ({ name, role, dialog, logout, mode, handleSetMode }) => {
  return (
    <div className="title-bar">
      <div className="title-options-bar">
        <div className="title-left-group">
          {name ? (
            <div>
              <img className="user-logo" alt="user" src={userLogo}></img>
              {name}
            </div>
          ) : (
            <div>Welcome back!</div>
          )}
          <div className="mode-select-container">
            <button
              className={
                'mode-select-btn ' +
                (mode === agentMode.SupportService ? 'selected' : '')
              }
              onClick={() => handleSetMode(agentMode.SupportService)}
            >
              Support Service
            </button>
            <button
              className={
                'mode-select-btn ' + (mode === agentMode.SalesAdvisor ? 'selected' : '')
              }
              onClick={() => handleSetMode(agentMode.SalesAdvisor)}
            >
              Sales Advisor
            </button>
          </div>
        </div>
        <div>
          {role === 'admin' && (
            <button onClick={() => dialog.current.showModal()}>
              <img alt="Logout" src={downloadLogo} />
              <div>Export</div>
            </button>
          )}
          <button onClick={() => logout()}>
            <img alt="Logout" src={logoutLogo} />
            <div>Sign out</div>
          </button>
        </div>
      </div>
    </div>
  );
};

export default TitleBar;
