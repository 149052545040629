import React, { useState } from 'react';
import _ from 'lodash';

import sendLogo from '../../assets/send.svg';
import loadingLogo from '../../assets/loading-black.svg';

// import './AiNavigator.scss';

import { recognizeOnce } from '../../adapters/speechServices';
import { useChatHistory } from './ChatHistoryContext';

import micLogo from '../../assets/mic.png';

const MessageBar = ({ productCategory, handleAsk, isInQuery }) => {
  const [micReady, setMicReady] = useState(true);
  const [query, setQuery] = useState('');
  // current query in the question box, and user's navigation history (using up and down keys)
  const [queryIndex, setQueryIndex] = useState(-1);

  const chatHistory = useChatHistory();

  const Dots = () => (
    <div
      style={{
        width: '29px',
        height: '29px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <div className="dot-flashing"></div>
    </div>
  );

  const handleKeyUp = async (event) => {
    event.preventDefault();
    if (event.key === 'Enter') {
      if (isInQuery) return;
      setQuery('');
      handleAsk(query);
      setQueryIndex(-1);
    }
    if (process.env.REACT_APP_NODE_ENV === 'development') {
      if (event.key === 'ArrowUp') {
        const history = _(chatHistory ?? [])
          .filter((e) => e.role === 'user')
          .value();
        let index = queryIndex !== -1 ? queryIndex - 1 : history.length - 1;
        index = Math.max(Math.min(index, history.length - 1), 0);
        setQuery(history[index]?.content?.text ?? '');
        setQueryIndex(index);
      }
      if (event.key === 'ArrowDown') {
        const history = _(chatHistory ?? [])
          .filter((e) => e.role === 'user')
          .value();
        let index = queryIndex !== -1 ? queryIndex + 1 : history.length;
        index = Math.max(Math.min(index, history.length), 0);
        setQuery(history[index]?.content?.text ?? '');
        setQueryIndex(index);
      }
    }
  };

  return (
    <div className="query-bar">
      {/* <div className="new-topic-button" ref={newTopicButton}
      onClick={() => handleNewTopic()}
      onMouseEnter={() => handleExpandButton()}
      onMouseLeave={() => handleCollapseButton()}
      >
      <div className="new-topic-text">{newTopicText}</div>
    </div> */}
      <div className="query-box">
        <input
          placeholder={
            !productCategory
              ? 'Please select a product category...'
              : 'Type your message...'
          }
          value={query}
          maxLength={1000}
          onKeyDown={(event) => event.stopPropagation()}
          onChange={(event) => setQuery(event.target.value)}
          onKeyUp={(event) => handleKeyUp(event)}
        />
        <button
          onClick={() => {
            setMicReady(false);
            recognizeOnce(
              ({ transcript }) => setQuery(query + transcript),
              () => setMicReady(true)
            );
          }}
        >
          {micReady ? <img alt="Enable mic" src={micLogo} /> : <Dots />}
        </button>
        {/* <button onClick={() => { getUserChatHistory().then(data => dispatchChatHistory({ type: 'fetch', payload: data.chatConversations })).catch(logout) }}>
        <img alt='Refresh' src={refreshLogo} />
      </button>
      <button onClick={() => { clearUserChatHistory(userId); dispatchChatHistory({ type: 'clear' }) }}>
        <img alt='Reset' src={trashLogo} />
      </button> */}

        <button
          onClick={() => {
            if (isInQuery) return;
            setQuery('');
            handleAsk(query);
          }}
        >
          {isInQuery ? (
            <img alt="Loading" src={loadingLogo} className='loading-icon' />
          ) : (
            <img alt="Send" src={sendLogo} />
          )}
        </button>
      </div>
    </div>
  );
};

export default MessageBar;
