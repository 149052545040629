import React, { useEffect, useState, useContext, useCallback } from "react";
import UserContext from '../contexts/user/user'
import { useNavigate, useParams } from "react-router-dom";
const ProtectedRoute = (props) => {
  const { company } = useParams();

  const navigate = useNavigate();
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [isVerifying, setIsVerifying] = useState(false)
  const { userId, verifyToken, hasLoginError, logout } = useContext(UserContext)

  const checkUserLoginStatus = useCallback(() => {
    console.log('Check user login status')
    if (userId !== null) {
      console.log(`Logging in to ${userId}...`)
      setIsVerifying(false)
      setIsLoggedIn(true);
    }
    else if (!isVerifying) {
      setIsVerifying(true)
      const accessToken = sessionStorage.getItem('access_token')
      const refreshToken = sessionStorage.getItem('refresh_token')
      if (accessToken && refreshToken) {
        console.log('Trying to login using stored tokens')
        verifyToken(accessToken, refreshToken)
      }
      else {
        console.log('No stored tokens, redirect to /login')
        setIsLoggedIn(false);
        logout()
        if (company) {
          navigate(`/login/${company}`)
        }
        else {
          navigate('/login')
        }
      }
    }
    else if (hasLoginError) {
      console.log('Stored tokens did not work, redirect to login')
      setIsLoggedIn(false);
      logout()
      if (company) {
        navigate(`/login/${company}`)
      }
      else {
        navigate('/login')
      }
    }
  }, [setIsLoggedIn, userId, hasLoginError, isVerifying, setIsVerifying, verifyToken, logout, navigate,])

  useEffect(() => {
    checkUserLoginStatus();
  }, [checkUserLoginStatus]);

  return (
    <React.Fragment>
      {
        isLoggedIn ? props.children : null
      }
    </React.Fragment>
  );
}
export default ProtectedRoute;