import React, {
  useState,
  useCallback,
  useEffect,
  forwardRef,
  useRef,
} from "react";
import {
  getAllProducts,
  exportCompareTable,
} from "../../adapters/restAPIServices";
import WebSocketService from "../../adapters/websocketServices";

import CompareToolbar from "./CompareToolbar";
import CompareTable from "./CompareTable";

import _ from "lodash";
import chevronLeft from "../../assets/chevron-left.svg";

import "./Compare.scss";

const Compare = ({
  selectedProductCodes = [null],
  setSelectedProductCodes,
  backToChat,
}) => {
  const [products, setProducts] = useState([]);
  const [productOptions, setProductOptions] = useState([]);
  const [selectedProductOptions, setSelectedProductOptions] = useState([null]);
  const [productFeatureData, setProductFeatureData] = useState(null);
  const [filterValue, setFilterValue] = useState("");

  const backendHost = process.env.REACT_APP_BACKEND_HOST;

  useEffect(() => {
    setTimeout(() => {
      WebSocketService.subscribe(
        "refresh-product-features",
        refreshProductFeatures
      );
    }, 500);
    return () => {
      WebSocketService.unsubscribe(
        "refresh-product-features",
        refreshProductFeatures
      );
    };
  }, []);

  useEffect(() => {
    refreshProductFeatures();
  }, []);

  useEffect(() => {
    setProductOptions(getProductOptions(products));
  }, [products]);

  useEffect(() => {
    setSelectedProductOptions(getSelectedProductOptions(selectedProductCodes));
  }, [productOptions, selectedProductCodes]);

  useEffect(() => {
    setProductFeatureData(getProductFeatureData(selectedProductCodes));
  }, [products, selectedProductCodes, filterValue]);

  function getProductOptions(products) {
    if (!Array.isArray(products)) return [];
    return products.map((product) => ({
      value: product.code,
      label: product.name,
      disabled: product.disabled,
      url: product.url,
    }));
  }

  function getSelectedProductOptions(productCodes) {
    if (!Array.isArray(productCodes)) return [];
    return productCodes.map(
      (code) => _.find(productOptions, { value: code }) ?? null
    );
  }

  function getProductFeatureData(productCodes) {
    if (
      !Array.isArray(productCodes) ||
      productCodes.length === 0 ||
      productCodes.every((code) => !code)
    ) {
      return null;
    }

    const selectedProductObjs = productCodes.map(
      (code) => _.find(products, { code: code }) ?? null
    );

    if (selectedProductObjs.every((productObj) => !productObj)) return null;

    const selectedProductNames = selectedProductObjs.map((productObj) =>
      productObj ? { name: productObj.code, url: productObj.url, title: productObj.name } : ""
    );
    const featureNames = Object.keys(selectedProductObjs[0].features).filter(feature => feature !== "Overview");
    const selectedProductFeatureRowValue = featureNames.map((featureName) => {
      const featureValues = selectedProductObjs.map((selectedProductObj) =>
        selectedProductObj ? selectedProductObj.features[featureName] : ""
      );
      return [featureName, ...featureValues];
    });

    const filteredSelectedProductFeatureRowValue =
      selectedProductFeatureRowValue.filter((rowValues) => {
        return rowValues.some((value) =>
          value.toLowerCase().includes(filterValue.toLowerCase())
        );
      });
    const productFeatureData = [
      selectedProductNames,
      ...filteredSelectedProductFeatureRowValue,
    ];
    return productFeatureData;
  }

  const refreshProductFeatures = () => {
    getAllProducts().then((data) => {
      let products = data.products;
      if (products.length === 0) return;
      setProducts(products);
    });
  };

  function selectProduct(selectedProductCodesIdx, productCode) {
    const updatedSelectedProductCodes = [...selectedProductCodes];
    updatedSelectedProductCodes[selectedProductCodesIdx] = productCode;
    setSelectedProductCodes(updatedSelectedProductCodes);
  }

  function deleteProduct(selectedProductCodesIdx) {
    setSelectedProductCodes(
      selectedProductCodes.filter(
        (selectedProductCode, currIdx) => currIdx !== selectedProductCodesIdx
      )
    );
  }

  function addProduct() {
    setSelectedProductCodes([...selectedProductCodes, null]);
  }

  let content;
  if (productFeatureData) {
    content = (
      <CompareTable
        productFeatureData={productFeatureData}
        filterValue={filterValue}
        setFilterValue={setFilterValue}
      />
    );
  } else {
    content = (
      <div className="hint-text">
        Please press the "+" button to add product
      </div>
    );
  }

  return (
    <div className="compare-container">
      <div className="nav-bar">
        <div onClick={() => backToChat()} style={{ cursor: "pointer" }}>
          <img className="user-logo" alt="user" src={chevronLeft}></img>
        </div>
        {/* <div>Compare product features</div> */}
      </div>
      <div className="main-container">
        <CompareToolbar
          productOptions={productOptions}
          selectedProductOptions={selectedProductOptions}
          selectProduct={selectProduct}
          deleteProduct={deleteProduct}
          addProduct={addProduct}
          productFeatureData={productFeatureData}
        />
        {content}
      </div>
    </div>
  );
};

export default Compare;
