import io from 'socket.io-client';

class WebSocketService {

  constructor() {
    this.socket = null;
    this.channel = null;
    this.init();
  }

  setChannel(channel) {
    if (this.channel !== channel) {
      this.channel = channel;
      if (this.socket && this.channel) this.register();
    }
  }

  register() {
    this.socket.emit('register', this.channel);
  }

  init() {
    this.socket = io(process.env.REACT_APP_WEBSOCKET_URL, {
      reconnection: true,
      reconnectionDelay: 1000,
      reconnectionDelayMax: 5000,
      reconnectionAttempts: 5,
      path: '/demo-socket'
    });
    // subscribe default server events...
    this.socket.on('connect', () => {
      console.log(`Websocket ready, connected to ${process.env.REACT_APP_WEBSOCKET_URL}`);
      if (this.socket && this.channel) this.register();
    });
    this.socket.on('disconnect', () => { console.log(`Disconnected from ${process.env.REACT_APP_WEBSOCKET_URL}`); });
    // subscribe default server events EOL
  }

  subscribe(event, callbackFN) {
    this.socket.off(event, callbackFN);
    this.socket.on(event, callbackFN);
    // console.log("subscribed, current listeners:", this.socket.listeners(event))
  } 

  unsubscribe(event, callbackFN) {
    this.socket.off(event, callbackFN);
    // console.log("unsubscribed, current listeners:", this.socket.listeners(event))
  }

  disconnect() {
    this.socket.disconnect();
  }

}
const service = new WebSocketService()
export default service