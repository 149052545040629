import React from "react";

import "./Tags.scss";

export default function Tags({ values = [] }) {
  return (
    <div className="tags-container">
      {values.map((value, idx) => (
        <div className="tag" key={idx}>
          <span>{value}</span>
        </div>
      ))}
    </div>
  );
}
