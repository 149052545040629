import React, { useReducer, useEffect } from 'react';
import { createBrowserRouter, createRoutesFromElements, RouterProvider, Route } from 'react-router-dom';
import {
  login as callLogin,
  verifyToken as callVerifyToken,
  agreeToTnC as callAgreeToTnC,
  addRequestInterceptor, addResponseInterceptor,
  removeRequestInterceptor, removeResponseInterceptor,
} from './adapters/restAPIServices';
import Menu from './pages/menu';
import Login from './pages/login';
import ProtectedRoute from './components/protectedRoute';
import UserContext, { initialState, reducer } from './contexts/user/user'
import './styles/common.scss';

const router = createBrowserRouter(
  createRoutesFromElements(
    <Route path="/" >
      <Route path="company/:company" element={<ProtectedRoute><Menu /></ProtectedRoute>} />
      <Route path="" element={<ProtectedRoute><Menu /></ProtectedRoute>} />
      <Route path="login/:company" element={<Login />} />
      <Route path="login" element={<Login />} />
      <Route path="*" element={<div>not found</div>} />
    </Route>
  ), {
    basename: "/demo",
  }
);

function App() {
  // user a reducer to manage UserContext
  const [state, dispatch] = useReducer(reducer, initialState)

  // global login method, available everywhere under UserContext loginStatus
  const login = async (username, password, isAdminLogin, loginDistrict) => {
    if (!isAdminLogin){
      if (username.length < 7) username = "0".repeat(7-username.length)+username
      if (loginDistrict.length < 4) loginDistrict = "0".repeat(4-loginDistrict.length)+loginDistrict
    }
    const { success, accessToken, refreshToken,
      userId, email, role, agreedToTnC, sessionId, name,
      message: serverMessage, errorMessage } = await callLogin(username, password, isAdminLogin, loginDistrict)
    if (success && sessionId && ((role === 'admin') === isAdminLogin)) {
      addRequestInterceptor(accessToken)
      addResponseInterceptor(refreshToken)
      sessionStorage.setItem("numberOfPreviousMessages", 0)
      dispatch({ type: 'login-success', payload: { userId, email, role, agreedToTnC, sessionId, name } })
    }
    else if (serverMessage) {
      dispatch({ type: 'login-error', payload: { error: serverMessage } })
    }
    else if ((role === 'admin') !== isAdminLogin) {
      dispatch({ type: 'login-error', payload: { error:  role === 'admin' ? 'Please log in as admin.' : 'Please log in as user.' } })
    }
    else {
      dispatch({ type: 'login-error', payload: { error: errorMessage ?? 'An unknown error occurred. Please try again later.' } })
    }
  }

  // global logout method, available everywhere under UserContext
  const logout = async () => {
    removeRequestInterceptor()
    removeResponseInterceptor()
    dispatch({ type: 'logout' })
  }

  // global verify token method, available everywhere under UserContext
  // this should be equivalent to a standard login
  const verifyToken = async (accessToken, refreshToken) => {
    addResponseInterceptor(refreshToken) // so that it benefits from the refresh interceptor
    const { success, _isRetry,
      userId, email, role, agreedToTnC, sessionId, name,
      message: serverMessage, errorMessage } = await callVerifyToken(accessToken)
    if (success && sessionId) {
      if (!_isRetry) { // if _isRetry is true then new tokens has already been set by the interceptor
        console.log('Saving new tokens')
        addRequestInterceptor(accessToken)
        addResponseInterceptor(refreshToken)
      }
      dispatch({ type: 'login-success', payload: { userId, email, role, agreedToTnC, sessionId, name, } })
    }
    else if (serverMessage) {
      dispatch({ type: 'login-error', payload: { error: serverMessage } })
    }
    else {
      dispatch({ type: 'login-error', payload: { error: errorMessage ?? 'An unknown error occurred. Please try again later.' } })
    }
  }

  const agreeToTnC = async () => {
    const { success,
      userId, email, role, agreedToTnC, sessionId, name,
      message: serverMessage, errorMessage } = await callAgreeToTnC()
    if (success) {
      dispatch({ type: 'login-success', payload: { userId, email, role, agreedToTnC, sessionId, name, } })
    }
    else if (serverMessage) {
      dispatch({ type: 'login-error', payload: { error: serverMessage } })
    }
    else {
      dispatch({ type: 'login-error', payload: { error: errorMessage ?? 'An unknown error occurred. Please try again later.' } })
    }
  }

  // things visible under this context
  const loginStatus = {
    // info from backend
    userId: state.userId, // also used as a proxy of whether the user is logged in
    email: state.email,
    role: state.role,
    agreedToTnC: state.agreedToTnC,
    sessionId: state.sessionId,
    name: state.name,

    // frontend display
    hasLoginError: state.hasLoginError,
    errorMessage: state.errorMessage,

    // flow control
    login,
    logout,
    verifyToken,
    agreeToTnC
  }
  return (
    // To use login flow
    <UserContext.Provider value={loginStatus}>
      <RouterProvider router={router} />
    </UserContext.Provider>

    // To bypass login flow (revert to demo behavior) 
    // something auth related things may still break
    // <Chatroom />
  );
}

export default App;