import _ from 'lodash';
import { v4 as uuidv4 } from 'uuid';
import React, {
  useState,
  useEffect,
  useRef,
  useCallback,
  useContext,
} from 'react';
import { useParams } from 'react-router-dom';

import { useChatHistory, useChatHistoryDispatch } from './ChatHistoryContext';

// adapters
import {
  getUserChatHistory,
  makeQueryV2,
  getProductMapping,
  verifyRespond,
} from '../../adapters/restAPIServices';

import TitleBar from './TitleBar';
import ChatHistory from './ChatHistory';
import AiNavigator from './AiNavigator';
import MessageBar from './MessageBar';
import Export from './Export';

// global user state
import UserContext from '../../contexts/user/user';

// styles
import '../../styles/chatroom.scss';

// Agent Options
import { agentMode } from '../../config/config'

const Chatroom = ({ setComparedProducts = () => {} }) => {
  const { company = 'aim' } = useParams();

  const [isInQuery, setIsInQuery] = useState(false);
  const [productCategory, setProductCategory] = useState(null);
  const [productName, setProductName] = useState(null);
  const [productContext, setProductContext] = useState(null);
  const [productMapping, setProductMapping] = useState([]);
  const [isCleanHistory, setIsCleanHistory] = useState(false);
  const [showCleanHistoryBtn, setShowCleanHistoryBtn] = useState(false);
  const [mode, setMode] = useState(agentMode.SupportService);

  const { userId, role, logout, sessionId, name } = useContext(UserContext);

  const chatBottom = useRef(null);
  const dialog = useRef(null);
  const scrollEnabled = useRef(true);
  const container = useRef(null);
  const productCategorySelect = useRef(null);

  // chat history
  const chatHistory = useChatHistory();
  const dispatchChatHistory = useChatHistoryDispatch();

  useEffect(() => {
    if (userId) {
      getUserChatHistory()
        .then((data) => {
          dispatchChatHistory({
            type: 'fetch',
            company,
            payload: { conversations: data.chatConversations, productMapping },
          });
          scrollToBottom();
        })
        .catch(logout);
    }
    getProductMapping(company).then((data) => {
      setProductMapping(data.response);
    });
    const iframeReady = (event) => {
      let message = event.data;
      if (message.scroll === true) {
        scrollEnabled.current = true;
        scrollToBottom('instant');
      }
      if (message.scroll === false) {
        scrollEnabled.current = false;
      }
    };
    window.addEventListener('message', iframeReady);
    window.parent.postMessage({ ready: true }, '*');
    return () => {
      window.removeEventListener('message', iframeReady);
    };
  }, [userId, logout]);

  useEffect(() => {
    const productsExtractedFromQuery = chatHistory.slice(-1)[0]?.products;
    if (!productsExtractedFromQuery?.length > 0) {
      return;
    }
    if (!productCategory) {
      setProductCategory({ value: null, label: 'Life' });
    }

    if (!productContext) {
      setProductContext(
        productsExtractedFromQuery.map((product) => ({
          productCode: product.code,
          productFileName: product.url?.split('/')?.pop()?.split('.')?.shift(),
          productName: product.name,
        })) ?? []
      );
    }
  }, [chatHistory]);

  const scrollToBottom = (behavior = 'smooth') => {
    if (scrollEnabled.current)
      setTimeout(() => chatBottom.current?.scrollIntoView({ behavior }), 300);
  };

  const handleAsk = useCallback(
    async (query) => {
      scrollToBottom();
      if (isInQuery) return;
      setIsInQuery(true);
      if (query === '') {
        return;
      }
      const messageId = uuidv4();
      const timestamp = new Date();
      let productCategories, productNames;
      if (!productCategory) {
        productCategories = [];
      } else {
        productCategories = Array.isArray(productCategory)
          ? productCategory.map((cat) => cat.value)
          : [productCategory.value];
        productCategories = productCategories.filter(
          (cat) => cat !== null && cat !== 'All Categories'
        );
      }
      if (!productName) {
        productNames = [];
      } else {
        productNames = Array.isArray(productName)
          ? productName.map((cat) => cat.value)
          : [productName.value];
        productNames = productNames.filter((name) => name !== null);
      }

      let productCodes =
        productContext?.map((context) => context.productCode) ?? [];

      // double for user + assistant messages
      const previousMessageRoles = ['user', 'assistant'];
      dispatchChatHistory({
        type: 'append',
        company,
        payload: {
          role: 'user',
          content: { text: query },
          messageId,
          sessionId,
          timestamp,
        },
      });
      // Append the assistant message in waiting status (which is "...")
      const streamId = `${sessionId}-${uuidv4()}`;
      dispatchChatHistory({
        type: 'append',
        company,
        payload: {
          role: 'assistant',
          userId,
          sessionId,
          messageId: null,
          streamId,
          status: 'waiting',
          content: { rawText: '', text: '', resources: [] },
          metadata: {},
        },
      });

      const controller = new AbortController();
      const signal = controller.signal;
      const onMessage = getOnMessageFn(streamId, controller);
      const onError = getOnErrorFn(streamId);

      await makeQueryV2(
        { text: query },
        messageId,
        company,
        true, // stream
        {
          previousMessageRoles,
          productCategories,
          productCodes,
          timestamp,
          isCleanHistory,
          mode,
        },
        { signal, onmessage: onMessage, onerror: onError }
      );

      if (isCleanHistory) {
        setIsCleanHistory(false);
      }
      setShowCleanHistoryBtn(true);
      setIsInQuery(false);
    },
    [
      company,
      chatHistory,
      dispatchChatHistory,
      isInQuery,
      setIsInQuery,
      productCategory,
      productName,
      isCleanHistory,
      sessionId,
      userId,
      productContext,
    ]
  );

  const getOnMessageFn = (streamId, controller) => (msg) => {
    const data = JSON.parse(msg.data);
    // Throw error if the chat response is an error
    if (msg.event === 'StreamEventError') {
      throw new Error(data);
    }
    // Close the stream if the conversation is done
    if (data.done) {
      controller.abort();
      return;
    }
    // Update the final response if the chat response is complete
    if (data.complete) {
      const { response, standaloneQuestion } = data;
      dispatchChatHistory({
        type: 'update',
        filter: (chat) => chat.streamId === streamId,
        payload: {
          ...response,
          status: 'done',
          metadata: {
            ...response.metadata,
            standaloneQuestion: standaloneQuestion,
          },
        },
      });
      return;
    }
    // Update the stream response
    const { messageId, userId, sessionId, delta = {} } = data;
    let { role = '', content = '' } = delta;
    if (content === null) {
      content = '';
    }
    if (!role && !content) {
      return;
    }
    dispatchChatHistory({
      type: 'stream',
      payload: {
        messageId,
        streamId,
        status: 'streaming',
        content: { text: content },
      },
    });
  };

  const getOnErrorFn = (streamId) => (err) => {
    console.error(err);
    setIsInQuery(false);
    const status = err?.status;
    const errorResponse = {
      role: 'assistant',
      content: {
        text:
          status == 429
            ? 'Maximum amount of requests reached. Please try again in a few minutes.'
            : 'An error has occurred. Please try again later.',
      },
      isError: true,
    };
    dispatchChatHistory({
      type: 'update',
      filter: (chat) => chat.streamId === streamId,
      payload: errorResponse,
    });
    throw err;
  };

  const handleCleanContext = useCallback(async () => {
    setProductContext(null);
    setProductCategory(null);
    setIsCleanHistory(true);
    setShowCleanHistoryBtn(false);
    const content = {
      text: 'I am ready for a new question, what can I do for you?',
    };
    dispatchChatHistory({
      type: 'append',
      company,
      payload: {
        role: 'assistant',
        content,
        metadata: { allowFeedback: false },
      },
    });
  }, []);

  const handleFactCheck = async (messageId) => {
    const respondChatIdx = chatHistory.findIndex(
      (chat) => chat.messageId === messageId
    );
    const question = chatHistory[respondChatIdx]?.metadata?.standaloneQuestion;
    if (!question) {
      return;
    }
    setIsInQuery(true);
    scrollToBottom();
    const {
      success,
      responses,
      standaloneQuestion,
      useOriginalQuery,
      errorResponse,
    } = await verifyRespond(messageId, question, company);

    if (success) {
      console.log(`standaloneQuestion: ${standaloneQuestion}`);
      const originalResponse = chatHistory[respondChatIdx];
      for (const response of responses) {
        originalResponse.verificationResult = response.verificationResult;
        dispatchChatHistory({ type: 'append', company, payload: response });
      }
    } else {
      dispatchChatHistory({ type: 'append', company, payload: errorResponse });
    }

    setIsInQuery(false);
  };

  const handleSetMode = (newMode) => { 
    if (newMode === mode) {
      return;
    }
    setProductContext(null);
    setProductCategory(null);
    setIsCleanHistory(true);
    setShowCleanHistoryBtn(false);
    setMode(newMode);
    const agentName = newMode === agentMode.SupportService ? 'Support Service' : 'Sales Advisor';
    const content = {
      text: `Switched to ${agentName} mode, what can I do for you?`,
    };
    dispatchChatHistory({
      type: 'append',
      company,
      payload: {
        role: 'assistant',
        content,
        metadata: { allowFeedback: false },
      },
    });
  }

  return (
    <div className="chatroom-container" ref={container}>
      {/* <div className="header-accent expand"></div>
      <div className="header-accent-white"></div> */}
      <TitleBar
        name={name}
        role={role}
        dialog={dialog}
        logout={logout}
        mode={mode}
        handleSetMode={handleSetMode}
      />
      <ChatHistory
        scrollToBottom={scrollToBottom}
        company={company}
        handleAsk={handleAsk}
        setProductCategory={setProductCategory}
        productCategorySelect={productCategorySelect}
        setComparedProducts={setComparedProducts}
        handleFactCheck={handleFactCheck}
        sessionId={sessionId}
        chatBottom={chatBottom}
      />
      <AiNavigator
        productCategory={productCategory}
        productMapping={productMapping}
        productContext={productContext}
        setProductCategory={setProductCategory}
        setProductName={setProductName}
        productName={productName}
        showCleanHistoryBtn={showCleanHistoryBtn}
        handleCleanContext={handleCleanContext}
      />
      <MessageBar
        productCategory={productCategory}
        handleAsk={handleAsk}
        isInQuery={isInQuery}
      />
      <Export dialog={dialog} />
    </div>
  );
};

export default Chatroom;
